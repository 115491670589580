'use client' // Error boundaries must be Client Components
import UAParserContext from '@/context/ua-parser'
import { useContext } from 'react'
import { Button } from '@/app/components/homepage-button'
import ClientGrid from '../grid'
import HeaderInit from '../header-init'

export const Error = () => {
    const { isBot } = useContext(UAParserContext)
    return (
        <>
            <HeaderInit>
                <div className="flex h-full w-full flex-col items-center justify-center py-[20px] pb-[50px]">
                    <video
                        className="fixed top-0 h-[1015px] opacity-[0.23]"
                        src="/static/img/videos/tron.mp4"
                        muted
                        playsInline
                        autoPlay
                        loop
                    />

                    <img
                        src="/static/img/error_pages/crying-cowbow-emoji.gif"
                        alt="Crying Cowboy Emoji"
                        className="isolate mt-[30px] h-[300px] w-[300px] opacity-100"
                    />

                    <div className="z-[1] my-[50px] mb-[20px] flex w-[400px] flex-col items-center text-center font-bold leading-[15px] opacity-100">
                        <h4 className="isolate mb-[0px] text-[17px] opacity-100" style={{ color: 'white' }}>
                            Oops! There&apos;s nothing here.
                        </h4>
                        <h4 className="mb-[7px] text-[17px]" style={{ color: 'white' }}>
                            For GIFs that DO exist, here&apos;s our trending feed...
                        </h4>
                    </div>

                    <div className="ss-icon ss-navigatedown mb-[15px] text-white" />

                    {isBot ? null : <ClientGrid />}
                    <div className="pointer-events-none fixed bottom-0 flex h-[30vh] w-screen items-end justify-center bg-gradient-to-t from-black/80 via-black/0">
                        <Button href="/">Continue to Our Homepage</Button>
                    </div>
                </div>
            </HeaderInit>
        </>
    )
}

export default Error
