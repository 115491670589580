import React from 'react'

type ButtonProps = {
    href: string
    children: React.ReactNode
}

export const Button = ({ href, children }: ButtonProps) => {
    return (
        <a
            href={href}
            className="pointer-events-auto mb-[60px] mt-0 w-[300px] text-center font-bold leading-[36px] text-white"
            style={{
                backgroundColor: 'rgb(97, 87, 255)',
            }}
        >
            {children}
        </a>
    )
}
